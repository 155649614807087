//@charset "utf-8"

// Import a Google Font
@import url('https://fonts.googleapis.com/css?family=Nunito:400,700')

// Set your brand colors
$purple: #8a4d76
$pink: #fa7c91
$brown: #757763
$beige-light: #d0d1cd
$beige-lighter: #eff0eb

// Update Bulma's global variables
$family-sans-serif: 'Nunito', sans-serif
$grey-dark: $brown
$grey-light: $beige-light
$primary: $purple
$link: $pink
$widescreen-enabled: false
$fullhd-enabled: false

// Update some of Bulma's component variables
$body-background-color: $beige-lighter
$control-border-width: 2px
$input-border-color: transparent
$input-shadow: none

// Import only what you need from Bulma
@import '../node_modules/bulma/sass/utilities/_all.sass'
@import '../node_modules/bulma/sass/base/_all.sass'
@import '../node_modules/bulma/sass/elements/button.sass'
@import '../node_modules/bulma/sass/elements/container.sass'
@import '../node_modules/bulma/sass/elements/form.sass'
@import '../node_modules/bulma/sass/elements/title.sass'
@import '../node_modules/bulma/sass/components/navbar.sass'
@import '../node_modules/bulma/sass/layout/hero.sass'
@import '../node_modules/bulma/sass/layout/section.sass'
@import '../node_modules/bulma/sass/components/dropdown.sass'

.entry-console
  position: absolute
  bottom: 5px
  width: 100%
  border: 1px solid #000000
  
.entry-input
  width: 100%
  font-size: 1.4em
  
.cameras-window
  position: fixed
  height: 100vh
  width: 100vw
  overflow-y: hidden
    
.camera-status
  position: absolute
  right: 10px
  width: 10em
  bottom: 10px
  background: #FFFFFF55
  color: #00FF00
  float: right
  z-index: 100
  border-radius: 15px
  
  opacity: 0
  height: 0px
  
  -webkit-transition: 0.5s
  -moz-transition: 0.5s
  -o-transition: 0.5s
  transition: 0.5s
  transition: bottom 300ms 0ms, opacity 300ms 0ms, height 300ms 0ms
  
.camera-status-padding
  padding: 20px    
  
.camera-status.go-up
  bottom: 52vh
  
.camera-status.show
  opacity: 1
  height: auto

.console-button-close
  float: right
  right: 10px
  bottom: 10px
  background: #000000
  color: #00FF00
  z-index: 110
  border-radius: 50px
  padding: 10px
  font-size: 1.3em
  margin: 5px

.console-button
  position: absolute
  left: 10px
  bottom: 10px
  background: #FFFFFF55
  color: #00FF00
  z-index: 100
  border-radius: 50px
  padding: 10px
  font-size: 1.3em
  opacity: 1
  height: auto
  
  -webkit-transition: 0.5s
  -moz-transition: 0.5s
  -o-transition: 0.5s
  transition: 0.5s
  transition: opacity 300ms 0ms, height 300ms 0ms
  
.console-button.hide
  opacity: 0
  height: 0px

.camera-button-close
  float: right
  right: 10px
  bottom: 10px
  background: #000000
  color: #00FF00
  z-index: 110
  border-radius: 50px
  padding: 10px
  font-size: 1.3em
  margin: 5px
  
.camera-button
  position: absolute
  right: 10px
  bottom: 10px
  background: #FFFFFF55
  color: #00FF00
  z-index: 100
  border-radius: 50px
  padding: 10px
  font-size: 1.3em
  opacity: 1
  height: auto
  
  -webkit-transition: 0.5s
  -moz-transition: 0.5s
  -o-transition: 0.5s
  transition: 0.5s
  transition: opacity 300ms 0ms, height 300ms 0ms
  
.camera-button.hide
  opacity: 0
  height: 0px

.terminal-section
  position: absolute
  bottom: 10px
  right: 10px
  width: calc(100vw - 20px)
  background: #FFFFFF55
  color: #00FF00
  float: right
  z-index: 100
  border-radius: 15px
  height: 0px
  
  -webkit-transition: 0.5s
  -moz-transition: 0.5s
  -o-transition: 0.5s
  transition: 0.5s
  transition: height 300ms 0ms, opacity 300ms 0ms
  
  opacity: 0
  
.terminal-padding
  padding: 20px  
  overflow-y: scroll
  height: 48vh

.terminal-section.show

  height: 50vh
  opacity: 1
  

.terminal-inside
  background: #00000000
  display: block
  
.terminal-inside.hide
  display: none
  
.camera-status-divider
  height: 1px
  width: 100%
  background-color: #00FF00
  margin-top: 5px
  margin-bottom: 5px
  
html
  background-color: #000000
  
.channels
  background-color: #00000000
  
.navbar
  background: #00000000
  position: fixed
  width: 100vw
  
.navbar-item
  color: #00FF00
  text-shadow: 0px 0px 4px #00FF00
  
  padding: 10px
  margin: 10px
  border-radius: 4px
  background: #000000
  border: 1px solid #666666

a.navbar-item:focus
  color: #00FF00  
  background-color: #000000
  border: 1px solid #666666
  
a.navbar-item:hover
  color: #00FF00  
  background-color: #000000
  border: 1px solid #666666
  
.button
  color: #00FF00
  text-shadow: 0px 0px 4px #00FF00
  
  background: #00000000
  border: none
  
.button:hover
  color: #FFFFFF  
  background-color: #444444
  
.login-hero
  @extend .hero-body

  width: 100vw
  height: 100vh
  position: absolute
    
  display: flex
  justify-content: center
  align-items: center
    
  background-color: #000000 
  background-size: cover
  background-position: center
  background-repeat: no-repeat

.login-box
  width: 35vw
    
  @include mobile
    width: 90%
    
  background-color: #444444
  text-align: center
    
  padding: 50px
  border-radius: 4px

.login-title
  color: #00FF00
  text-shadow: 0px 0px 4px #00FF00
  font-size: 1.3em

@media only screen and (max-width: 800px)
  .login-box
    
    width: 80vw
    
    background-color: $white
    box-shadow: black 0px 5px 15px -5px
    text-align: center
    
    padding: 50px
    border-radius: 5px

.login-input
  width: calc(100% - 40px)
  font-size: 1.3em
  border: 1px solid #000000
  border-radius: 3px
  padding: 5px
  margin: 20px
  
  background-color: #000000
  color: #00FF00

.certificates
  margin-top: 30px
  color: #FFFFFF
  padding: 10px

.left-side
  padding: 10px
  width: calc(50% - 10px)
  display: inline-block
  border: 1px solid #666666

.right-side
  padding: 10px
  width: calc(50% - 10px)
  display: inline-block
  float: right
  border: 1px solid #666666

.error-bar 
  width: 100%
  padding: 40px
  background: #FF0000
  color: #FFFFFF
  font-size: 1.3em
