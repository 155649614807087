
.fade-enter {
  opacity: 0.01;
  translate: 0px,-50px;
  top: -20px;
}
.fade-enter-active {
  translate: 0px,0px;
  opacity: 1;
  top: 0px;
  transition: opacity 500ms, top 500ms;
}
.fade-exit {
  opacity: 1;
  top: 0px;
  transition: opacity 500ms, top 500ms;
}
.fade-exit-active {
  opacity: 0.01;
  top: -5em;
  transition: opacity 500ms, top 500ms;
}
.toast{
  margin: 0px !important;
}
.toast-container{
  position: fixed;
  width: 100%;
  z-index: 101;
}
